#udu-mailer-block-div {
  background: #000;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8000;
}
.div-mailer-form .mailer-form div.tox.tox-tinymce[role=application] {
  margin-top: 3px !important;
  height: calc(100vh - 87px) !important;
}
/*mailer window*/
#udu-mailer-wnd .close-btn:hover {
  background-position: 0 -32px;
}
#udu-mailer-wnd .close-btn {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -13px;
  top: -11px;
  background: url("../img/mailer/win-close.png") 0 0 no-repeat;
  cursor: pointer;
  display: block;
  z-index: 999;
  transition: all 0s ease-in-out 0s;
}
#udu-mailer-wnd > .body > .content {
  bottom: 33px;
  left: 5px;
  overflow: hidden;
  position: absolute;
  right: 5px;
  top: 33px;
  z-index: 50;
}
#udu-mailer-wnd .top-bar .top-bar-inner a.active:hover {
  text-decoration: underline;
}
#udu-mailer-wnd .top-bar .top-bar-inner a.active {
  color: #ff4500;
  cursor: pointer;
}
#udu-mailer-wnd .top-bar .top-bar-inner a {
  color: #888;
  cursor: default;
  text-decoration: none;
}
#udu-mailer-wnd .top-bar .top-bar-inner {
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#udu-mailer-wnd .top-bar {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 30px;
  right: 5px;
  border-bottom: 1px solid #ddd;
  background: #f5f5f5 url("../img/mailer/mail.png") 5px 5px no-repeat;
  z-index: 100;
  text-align: left;
  padding: 0 10px 0 30px;
  line-height: 27px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#udu-mailer-wnd .bottom-bar .blk-2 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 4px !important;
}
#udu-mailer-wnd .bottom-bar .blk-2 button {
  margin: 0 0 0 5px;
  opacity: 0.01;
  padding: 6px 10px !important;
  font-size: 12px !important;
}
#udu-mailer-wnd .bottom-bar {
  align-items: center;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  bottom: 3px;
  display: flex;
  height: 40px !important;
  justify-content: end;
  left: 5px;
  padding: 0 10px;
  position: absolute;
  right: 5px;
  text-align: left;
  z-index: 100;
}
#udu-mailer-wnd .body {
  border: 1px solid #aaa;
  border-radius: 8px;
  padding: 3px;
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  bottom: 16px;
  box-shadow: 0 0 5px #ccc;
  background-color: #f5f5f5;
}
#udu-mailer-wnd {
  position: fixed;
  top: -100px;
  height: 100px;
  width: 100px;
  opacity: 0;
  z-index: 8999;
  min-width: 420px !important;
  max-height: 600px;
}
@media all and (max-width: 540px) {
  #udu-mailer-wnd {
    left: 10px !important;
    right: 10px !important;
    width: auto !important;
    min-width: 300px !important;
  }
}
.loading {
  background: url("../img/preloader.gif") center center no-repeat;
}
#udu-mailer-wnd iframe.iframe-mailer {
  border: none;
  width: 100%;
  height: 100%;
}
iframe.not-loaded {
  visibility: hidden;
}
table.mailer-form {
  border-collapse: collapse;
  width: 100%;
}
/*form css*/
.mailer-form input {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #CCCCCC inset;
  border-radius: 2px;
  padding: 4px;
  width: 70%;
}
.mailer-form tr td.label {
  width: 50px;
  white-space: nowrap;
  vertical-align: top;
  padding-top: 5px;
}
.mailer-form {
  font-size: 12px;
  font-family: arial, tahoma, verdana, helvetica, sans-serif;
}
.mailer-form .comments {
  padding: 2px 5px 5px 10px;
  font-size: 9px;
  color: #999;
}
